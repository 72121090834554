var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"任务池"}},[_c('a-row',{staticStyle:{"margin-bottom":"2vh"}},[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"任务单号"},model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"任务类型"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-radio-group',{model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statusList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-button',{attrs:{"type":"primary","disabled":!_vm.permissionAdd},on:{"click":function($event){return _vm.$router.push('/manage/map?page=task&action=add')}}},[_vm._v("新建任务")])],1)])],1),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
      total: _vm.total,
      current: _vm.current,
      pageSize: _vm.pageSize,
      showTotal: (total) => `共 ${total} 条记录`,
    },"rowKey":"number"},on:{"change":_vm.onChange}},[_c('a-table-column',{key:"number",attrs:{"title":"任务单号","data-index":"number"}}),_c('a-table-column',{key:"name",attrs:{"title":"任务名称","data-index":"name"}}),_c('a-table-column',{key:"type",attrs:{"title":"任务类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"taskType","dictValue":text.type}})]}}])}),_c('a-table-column',{key:"taskTime",attrs:{"title":"任务日期","data-index":"taskTime","align":"center"}}),_c('a-table-column',{key:"initiatorName",attrs:{"title":"发起人","data-index":"initiatorName","align":"center"}}),_c('a-table-column',{key:"executorName",attrs:{"title":"执行人","data-index":"executorName","align":"center"}}),_c('a-table-column',{attrs:{"title":"状态","data-index":"status","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(status){return [_c('DataDictFinder',{attrs:{"dictType":"taskStatus","dictValue":String(status)}})]}}])}),_c('a-table-column',{key:"control",attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionEdit),expression:"permissionEdit"}]},[(text.status === 'reviewing')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
        '/manage/task-review?id=' + text.id + '&type=' + text.type
      )}}},[_vm._v("审核")]):_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
        '/manage/map?page=task&action=edit&id=' +
        text.id +
        '&type=' +
        text.type
      )}}},[_vm._v("编辑")]),_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }