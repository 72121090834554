<template>
  <Card title="任务池">
    <a-row style="margin-bottom: 2vh">
      <a-col :span="22">
        <a-form-model layout="inline" @keyup.enter.native="query">
          <a-form-model-item>
            <a-input v-model="form.number" placeholder="任务单号" />
          </a-form-model-item>
          <a-form-model-item>
            <a-select v-model="form.type" placeholder="任务类型" style="width: 150px">
              <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-radio-group v-model="form.status">
              <a-radio v-for="item in statusList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>

      <a-col :span="2">
        <div class="right" style="margin-top: 4px">
          <a-button @click="$router.push('/manage/map?page=task&action=add')" type="primary"
            :disabled="!permissionAdd">新建任务</a-button>
        </div>
      </a-col>
    </a-row>

    <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }" rowKey="number">
      <a-table-column key="number" title="任务单号" data-index="number"></a-table-column>
      <a-table-column key="name" title="任务名称" data-index="name"></a-table-column>
      <a-table-column key="type" title="任务类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="taskType" :dictValue="text.type" />
        </template>
      </a-table-column>

      <a-table-column key="taskTime" title="任务日期" data-index="taskTime" align="center"></a-table-column>

      <a-table-column key="initiatorName" title="发起人" data-index="initiatorName" align="center"></a-table-column>
      <a-table-column key="executorName" title="执行人" data-index="executorName" align="center"></a-table-column>

      <a-table-column title="状态" data-index="status" align="center">
        <template slot-scope="status">
          <DataDictFinder dictType="taskStatus" :dictValue="String(status)" />
        </template>
      </a-table-column>

      <a-table-column key="control" title="操作" align="center">
        <template slot-scope="text">
          <a-space v-show="permissionEdit">
            <a href="#" v-if="text.status === 'reviewing'" @click.prevent="
        $router.push(
          '/manage/task-review?id=' + text.id + '&type=' + text.type
        )
        ">审核</a>

            <a v-else href="#" @click.prevent="
        $router.push(
          '/manage/map?page=task&action=edit&id=' +
          text.id +
          '&type=' +
          text.type
        )
        ">编辑</a>
            <a href="#" @click.prevent="deleteText(text)" class="danger">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </Card>
</template>

<script>
import { fetchList, remove } from "../api/task";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["hasPermission"]),
    typeList() {
      return this.findDataDict("taskType");
    },
    statusList() {
      return this.findDataDict("taskStatus");
    },
    permissionAdd() {
      return this.hasPermission("model-analysis:inspectTask:add")
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:inspectTask:update")
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          remove(text).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>