import request from '@/api/request'

export function fetchList(data) {
    return request({
        url: '/model-analysis/inspectTask/list',
        method: 'post',
        data,
    })
}

export function fetchDetail(data) {
    return request({
        url: `/model-analysis/inspectTask/query/${data.id}/${data.type}`,
    })
}

export function add(data) {
    return request({
        url: '/model-analysis/inspectTask/save',
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/inspectTask/modify',
        method: 'post',
        data,
    })
}

export function remove({ id, type }) {
    return request({
        url: '/model-analysis/inspectTask/delete/' + id + '/' + type,
        method: 'post'
    })
}

export function editStatus({ id, type, status }) {
    return request({
        url: `/model-analysis/inspectTask/modifyStatus/${id}/${type}/${status}`,
        method: 'post',
    })
}